<template>
  <main class="home">
    <div class="hero is-primary">
      <div class="hero-body">
        <h1 class="title is-1 has-text-centered">About</h1>
      </div>
    </div>
    <div class="container">
      <br /><br /><br />
      <div class="columns">
        <div class="column is-3 left">
          <img
            class="rounded image"
            src="@/assets/images/alan_profile.jpg"
            alt="Alan Holt profile picture"
          />
          <br />
          <h1 class="title is-2">Alan Holt</h1>
          <h2 class="subtitle">President</h2>
          <p>(313) 461-8705</p>
          <p>(313) 397-4093</p>
          <p><a href="mailto:alan@jones-holt.com">alan@jones-holt.com</a></p>
          <br />
          <p>Jones-Holt Commercial</p>
          <p>12811 Hillview</p>
          <p>Detroit, MI 48227</p>
        </div>
        <div class="column is-8 right">
          <h2 class="subtitle">Professional Experience</h2>
          <p>
            Alan is President and Associate Broker for Jones-Holt Commercial and
            is responsible for the leasing, sale and expansion of industrial
            technology research properties as well as land sales and has
            negotiated over $25 million in transactions, numerous land sales to
            tenure in the business.
          </p>
          <br />
          <p>
            Combined with his years of experience and intuitive knowledge of the
            industry, Alan plays an integral role within the Detroit Industrial
            Team where he specializes in exclusive listings in Oakland and Wayne
            Counties. His expertise has enabled Alan to establish himself as a
            top producer in the market. His approach to teamwork is unsurpassed
            providing his clients with opportunities through the Jones-Holt
            Commercial network while incorporating the company’s broad real
            estate services. Alan has also saved corporations and other entities
            substantial amounts of money with tenant representation
          </p>
          <h2 class="subtitle">Professional Credentials</h2>
          <p>
            <i class="fal fa-check-circle" style="margin-right: 0.5rem"></i>
            Michigan Real Estate Broker License
          </p>
          <p>
            <i class="fal fa-check-circle" style="margin-right: 0.5rem"></i>
            Certified Commercial Investment Member
          </p>
          <p>
            <i class="fal fa-check-circle" style="margin-right: 0.5rem"></i>
            Philanthropist for many charities including: St. Jude’s, American
            Heart Association and Brain Injury Association
          </p>
          <h2 class="subtitle">Education</h2>
          <p>
            <i class="fal fa-school" style="margin-right: 0.5rem"></i>
            Wayne State University - Bachelor of Accounting
          </p>
          <p>
            <i class="fal fa-school" style="margin-right: 0.5rem"></i>
            Henry Ford Community College, Associates of Business Administration
          </p>
          <h2 class="subtitle">Clients</h2>
          <div class="columns is-multiline">
            <div
              v-for="(client, index) in clients"
              :key="index"
              class="column is-6"
            >
              <p>
                <i class="fal fa-check-circle" style="margin-right: 0.5rem"></i>
                {{ client }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <!-- Any other Bulma elements you want -->
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  </main>
</template>

<script>
export default {
  name: "Browse",
  data: () => {
    return {
      propertyData: undefined,
      inventoryCoordinates: undefined,
      modal: false,
      accessToken:
        "pk.eyJ1IjoibXR3aGNyZSIsImEiOiJja3BnMDcwcnIwYjFsMnVvOTMxM2JhdmJjIn0.yKkEMUKcPRyti3ED3mOQUg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mtwhcre/ckpotrod00aef17s4x08mzua5", // your map style
      center: [-83.37736141151493, 42.48706224043698],
      clients: [
        "Versa Handling",
        "Vertex Steel",
        "Boydell Loft Development",
        "Stuart Frankel Development",
        "Outfront Media",
        "City of Detroit",
        "City of Inkster",
        "Detroit Free Press",
        "Faygo Beverages",
        "AFL-CIO",
        "Theotis Group",
        "PVS Chemicals INC.",
        "Missant Properties.",
      ],
    };
  },
  computed: {
    routeID: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    async loadPropertyData() {
      this.$prismic.client
        .query(
          this.$prismic.Predicates.at(
            "my.listing.hillview_internal_id",
            this.routeID
          )
        )
        .then((res) => {
          if (res.results_size === 0) this.bail();
          else this.propertyData = res.results[0];
        });
    },
    bail() {
      this.$router.push("/inventory");
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  background: #1a1f36;
  min-height: 100vh;
  color: #ffffffe3;
  font-size: 1.25rem;
  padding-bottom: 20rem;
  letter-spacing: 0.02rem;
  font-family: "Lato", sans-serif;
}
.navbar-item,
.title {
  color: #d8af64 !important;
  font-family: "Playfair Display";
  letter-spacing: 0.1rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
.subtitle {
  color: #d8af64;
  font-family: "Playfair Display", serif;
  margin-top: 1.5rem;
  font-size: 1.6rem;
}
hr {
  background: hsl(39, 40%, 32%);
}
.rounded {
  border-radius: 100%;
}
.text {
  color: #ffffffe3;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.05rem;
}
@media only screen and (max-width: 600px) {
  .left {
    text-align: center;
    justify-content: center;
    img {
      width: 100%;
      padding: 0rem 5rem;
    }
  }
  .right {
    padding: 2rem;
  }
}
</style>
